import { template as template_89f4a7b3f8e04a318953865a147eddba } from "@ember/template-compiler";
const FKText = template_89f4a7b3f8e04a318953865a147eddba(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
